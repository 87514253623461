<template>
   <!-- Error page-->
   <div class="misc-wrapper">
	  <b-link class="brand-logo">
		 <vuexy-logo />
	  </b-link>

	  <div class="misc-inner p-2 p-sm-3">
		 <div class="w-100 text-center">
			<h2 class="mb-1">
			   {{ $t('error.not-found') }}
			</h2>
			<p class="mb-2">
			   {{ $t('error.page-not-found') }}
			</p>

			<b-button
				:to="{path:'/'}"
				class="mb-2 btn-sm-block"
				variant="primary"
			>
			   {{ $t('error.return-home') }}
			</b-button>

			<!-- image -->
			<b-img
				:src="imgUrl"
				alt="Error page"
				fluid
			/>
		 </div>
	  </div>
   </div>
   <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {BButton, BImg, BLink} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

export default {
   components: {
	  VuexyLogo,
	  BLink,
	  BButton,
	  BImg,
   },
   data() {
	  return {
		 downImg: require('@/assets/images/pages/error.svg'),
	  }
   },
   computed: {
	  imgUrl() {
		 if (store.state.appConfig.layout.skin === 'dark') {
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			this.downImg = require('@/assets/images/pages/error-dark.svg')
			return this.downImg
		 }
		 return this.downImg
	  },
   },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
